import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { FeedbackSummary } from "../interfaces/Feedback";
import { BusinessLeadStatus } from "../../../interfaces/Lead";
import { getBusinessLeadStatuses } from "../../../services/lead";
import useUser from "../../../hooks/useUser";


interface ActivityLogCount {
  transactions: string;
  leads: string;
  services: string;
  feedbacks: string;
  complaints: string;
}
interface Recommendations {
  product: string;
  score: number;
  image: string;
}

interface Customer360 {
  leads: Record<string, any>;
  cartItems: Record<string, any>;
  productViews: Record<string, any>;
  recommendations: Record<string, any>;
  serviceData: Record<string, any>;
  feedbackSummary: Record<string, any>;
  feedbackList: Record<string, any>;
  complaints: Record<string, any>;
  loyaltyDetails: Record<string, any>;
  activityLogCount: Record<string, any>;
  recentOtp: Record<string, any>;
  offers: Record<string, any>;
  businessLeadStatuses: BusinessLeadStatus[];
  milestoneDetails: Record<string, any>;
  setMilestoneDetails: (milestoneDetails: Record<string, any>) => void;

  setLeads: (lead: Record<string, any>) => void;
  setCartItems: (cartItem: Record<string, any>) => void;
  setProductViews: (productView: Record<string, any>) => void;
  setRecommendations: (recommendation: Record<string, any>) => void;
  setServiceData: (serviceData: Record<string, any>) => void;
  setFeedbackSummary: (feedback: Record<string, any>) => void;
  setFeedbackList: (feedback: Record<string, any>) => void;
  setComplaints: (complaint: Record<string, any>) => void;
  setLoyaltyDetails: (loyaltyDetails: Record<string, any>) => void;
  setActivityLogCount: (activityLogCount: Record<string, any>) => void;
  setRecentOtp: (recentOtp: Record<any, any>) => void;
  setOffers: (offers: Record<string, any>) => void;
  setBusinessLeadStatuses: (businessLeadStatuses: BusinessLeadStatus[] ) => void;
  refresh: () => void;
}

const CustomerContext = createContext<Customer360>({
  milestoneDetails: {},
  setMilestoneDetails: () => {},
  leads: {},
  cartItems: {},
  productViews: {},
  recommendations: {},
  serviceData: {},
  feedbackSummary: {},
  feedbackList: {},
  complaints: {},
  loyaltyDetails: {},
  activityLogCount: {},
  recentOtp: {},
  offers: {},
  businessLeadStatuses: [],
  setLeads: () => {},
  setCartItems: () => {},
  setProductViews: () => {},
  setRecommendations: () => {},
  setServiceData: () => {},
  setFeedbackSummary: () => {},
  setFeedbackList: () => {},
  setComplaints: () => {},
  setLoyaltyDetails: () => {},
  setActivityLogCount: () => {},
  setRecentOtp: () => { },
  setOffers: () => {},
  setBusinessLeadStatuses: () => {},
  refresh: () => {},
});

export const CustomerContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [milestoneDetails, setMilestoneDetails] = useState<Record<string, any>>({});
  const [leads, setLeads] = useState<Record<string, any>>({});
  const [cartItems, setCartItems] = useState<Record<string, any>>({});
  const [productViews, setProductViews] = useState<Record<string, any>>({});
  const [recommendations, setRecommendations] = useState<
    Record<string, Recommendations[]>
  >({});
  const [serviceData, setServiceData] = useState<Record<string, any>>({});
  const [complaints, setComplaints] = useState<Record<string, any>>({});
  const [loyaltyDetails, setLoyaltyDetails] = useState<Record<string, any>>({});
  const [feedbackSummary, setFeedbackSummary] = useState<
    Record<string, FeedbackSummary[]>
  >({});
  const [feedbackList, setFeedbackList] = useState<Record<string, any>>({});
  const [activityLogCount, setActivityLogCount] = useState<
    Record<string, ActivityLogCount>
  >({});
  const [recentOtp, setRecentOtp] = useState<Record<string, any>>({});
  const [offers, setOffers] = useState<Record<string, any>>({});
  const [businessLeadStatuses, setBusinessLeadStatuses] = useState<BusinessLeadStatus[]>([]);
  const refresh = () => {
    setMilestoneDetails({});
    setLeads({});
    setCartItems({});
    setProductViews({});
    setRecommendations({});
    setServiceData({});
    setComplaints({});
    setLoyaltyDetails({});
    setFeedbackSummary({});
    setFeedbackList({});
    setActivityLogCount({});
    setRecentOtp({});
    setOffers({});
    // setBusinessLeadStatuses([]);
  };

  const { selectedBusiness } = useUser();

  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const res = await getBusinessLeadStatuses(selectedBusiness.id);
        setBusinessLeadStatuses(res);
      } catch (error) {
        console.error("Error fetching business lead statuses:", error);
      }
    };
    fetchStatuses();
  }, [selectedBusiness]);

  return (
    <CustomerContext.Provider
      value={{
        milestoneDetails,
        setMilestoneDetails,
        leads,
        setLeads,
        cartItems,
        setCartItems,
        productViews,
        setProductViews,
        recommendations,
        setRecommendations,
        serviceData,
        setServiceData,
        complaints,
        setComplaints,
        loyaltyDetails,
        setLoyaltyDetails,
        feedbackSummary,
        setFeedbackSummary,
        feedbackList,
        setFeedbackList,
        activityLogCount,
        setActivityLogCount,
        recentOtp,
        setRecentOtp,
        offers,
        setOffers,
        businessLeadStatuses,
        setBusinessLeadStatuses,
        refresh,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};
const useCustomer360 = () => {
  return useContext(CustomerContext);
};

export { useCustomer360, CustomerContext };
