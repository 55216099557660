import axios from 'axios';
import config from '../config';
import User from '../interfaces/User';
import { BusinessLeadStatus } from '../interfaces/Lead';

const { apiServerUrl } = config;

export const getLeadSources = (businessUnitId: number) => {
  return axios
    .get<string[]>(`${apiServerUrl}/users/walk-in-interest/businessUnit/${businessUnitId}/lead-sources`)
    .then((res) => res.data);
};

export const getLeadOwners = (businessUnitId: number) => {
  return axios
    .get<User[]>(`${apiServerUrl}/users/walk-in-interest/businessUnit/${businessUnitId}/lead-users`)
    .then((res) => res.data);
};

export const getBusinessLeadStatuses = async (
  tenantBrandBusinessId: number
): Promise<BusinessLeadStatus[]> => {
  return axios
    .get(`${config.apiServerUrl}/users/lead-status/businessUnit/${tenantBrandBusinessId}`)
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
};